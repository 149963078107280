/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { validatar } from "../../utils/validation";
import * as yup from "yup";
import TextField from "../Form/TextField";
import SelectCard from "../Form/SelectCard";
import { cardSwapMessage, makeSwapDealService } from "../../services/cardService";
import Swal from "sweetalert2";
export default function ForSwapActive({ swapData,collection="" }) {
   const [show,setShow]=useState(false);
   const [emailModal,setEmailModal]=useState(false);
   const [data,setData]=useState([]);
   const [swapUserData,setSwapUserData]=useState([]);
   const formik = useFormik({
    initialValues: {
      member:"",
      collection:"",
      card_got:"",
      card_needed:"",
      message:"",
      email:"",
    },
    validationSchema: yup.object({
     card_got:validatar("Card Got is required"),
     card_needed:validatar("Card Need is required"),
     message:validatar("Message is required"),
    }),
    onSubmit: async (values) => {
      
       const result = await cardSwapMessage(values)
       console.log("the reuslt is",result)
       if(result){
        setEmailModal(false)
        Swal.fire('success','Message has been successfully','success')
      }
      else{
         Swal.fire('error','Something went wrong','error')
        
       }
      }
    
    
  });

  const handleMakeADeal=async(user_id)=>{
      console.log("the id is",user_id);
      const  result = await makeSwapDealService(user_id)
      if(result){
        Swal.fire('success',"Deal successfully sent to the user!")
      
      }

  }
  useEffect(()=>{
 
     formik.setFieldValue('member',swapUserData.forename);
     formik.setFieldValue('email',swapUserData.email);
     formik.setFieldValue('collection',collection.replaceAll("-"," "));
  },[swapUserData])
  
  return (
    <>
    {swapData.loginUserCards?.swap_cards?.length>0 ?
      <div className="container">
        <h3 className="py-4">My Cards</h3>
        <div className="row">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                {/* <th scope="col">Quantity</th> */}
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              {swapData?.loginUserCards &&
                swapData?.loginUserCards?.swap_cards?.map(
                  (item, index) => (
                    <tr key={index}>
                        {console.log("the item is",swapData)}
                      <th scope="row">{item.id}</th>
                      <td>{item.name}</td>
                      {/* <td>@mdo</td> */}
                      <td>{item.sale_price}</td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
        <div className="row pt-3">
        <h3 className="pt-4">Available Cards</h3>
        <div className="pt-2">
            {swapData?.otherUserCards?.map((item,index)=>(

            
        <table key={index} className="table table-hover my-4">
            <thead>
              <tr >
              
                <th scope="col" >Member</th>
                {/* <th scope="col">Quantity</th> */}
                <th scope="col">Your Needs (needed by swaps)</th>
                <th scope="col" className="text-center">Action</th>

              </tr>
            </thead>
            <tbody>
            <tr>
                <td style={{width:"200"}}>
                    {item.forename}
                </td>
                <td>
                  <p className="p-0 m-0">{item.forename} has {item.swap_cards.length} cards:</p>
                  <div class="d-flex justify-content-start align-items-center">
                    {item.swap_cards.map((swapItem, swapIndex)=>(
                    <div key={swapIndex} className="px-1">
                  
                        <span >{swapItem.id}</span>,
                    </div>
                    ))}
                  </div>
                  </td>
                  <td className="d-flex pb-4 h-100 flex-row justify-content-center align-items-center">
                    <div className="px-2">
                    <button className="btn btn-primary" onClick={()=>{
                      setShow(true)
                      setData(item.swap_cards)
                      }}>Details</button>

                    </div>
                    <div>

                    <button className="btn btn-primary" onClick={()=>{setEmailModal(true); setSwapUserData(item)}}>Email</button>
                    <button className="btn btn-primary" onClick={()=>{handleMakeADeal(item.subscription?.user_id)}}>Make a Deal</button>
                    </div>
                  </td>
            </tr>
            </tbody>
          </table>
          ))}
        </div>
        </div>
      </div>
      :
      <div className="container">
        <div className="d-flex justify-content-center text-center align-items-center vh-100">
              <h1>Not Match Found</h1>
        </div>
        </div>}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        dialogClassName="bg-white w-100"
      >
        <Modal.Header className="border-bottom-0 pb-0" closeButton>
          <Modal.Title as="h4" className="w-100 text-center p-0 ">
            Cards Details
          </Modal.Title>
        </Modal.Header>
      {/* detail model start */}
        <Modal.Body className="text-dark">
        <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                {/* <th scope="col">Quantity</th> */}
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.map(
                  (item, index) => (
                    <tr key={index}>
                     
                      <th scope="row">{item.id}</th>
                      <td>{item.name}</td>
                      {/* <td>@mdo</td> */}
                      <td>{item.sale_price}</td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      {/* detail model end */}
     {/* model start */}
      <Modal
        show={emailModal}
        onHide={() => setEmailModal(false)}
        animation={false}
        dialogClassName="bg-white w-100"
      >
        <Modal.Header className="border-bottom-0 pb-0" closeButton>
          <Modal.Title as="h4" className="w-100 text-center p-0 ">
            Send Email to {swapUserData.forename}
          </Modal.Title>
        </Modal.Header>
      
        <Modal.Body className="text-dark">
       <div>
        <div className="row">
      
       <form onSubmit={formik.handleSubmit} class="col-md-12">
       <div class="field-set">
                  <SelectCard
                    options={swapData?.loginUserCards?.swap_cards}
                    label="Cards Got"
                    name="card_got"
                    placeholder="Cards Got"
                    formik={formik}
                   
                  />
                </div>
       <div class="field-set">
                  <SelectCard
                    options={swapUserData?.swap_cards}
                    label="Cards Need"
                    name="card_needed"
                    placeholder="Cards Need"
                    formik={formik}
                   
                  />
                </div>
                <div class="field-set">
                  <TextField
                    label="Message"
                    name="message"
                    multi={true}
                    placeholder="Enter Message"
                    formik={formik}
                  />
                </div>
                <div class="field-set">
                  <button className="btn btn-primary" type="submit">Send Message</button>
                </div>
              </form>
       </div>
       </div>
        </Modal.Body>
      </Modal>
      {/* model end */}
    </>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ColllectionsCard from "../Home/ColllectionsCard";
import { useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { getAllCards } from "../../services/subCollectionsService";

const AllcardscC = () => {
  const { name } = useParams();
  const [data, setData] = useState([]);
  const [url, setUrl] = useState("");
  const [loader, setLoader] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));
  const allSubCollectionsData = async (name) => {
    setLoader(true);
    const result = await getAllCards(name, user?.id);
    if (result.card) {
      setData(result.card);
      setLoader(false);
      setUrl(result.imagePath);
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    allSubCollectionsData(name);
    console.log("the name is", name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
  return (
    <>
      {!loader ? (
        <div className="pt-5">
          <div class="container">
            <div class="row fadeIn">
              <div class="col-lg-12">
                <div class="items_filter w-100">
                  <form class="row form-dark w-100" id="form_quick_search">
                    <div class="col text-center w-100">
                      <input
                        class="form-control "
                        id="name_1"
                        name="name_1"
                        placeholder="search item here..."
                        type="text"
                      />{" "}
                      <a href="#" id="btn-submit">
                        <i class="fa fa-search bg-color-secondary"></i>
                      </a>
                      <div class="clearfix"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* new collection */}

            <div className="New Collections">
              <h2>{data?.length === 0 ? "No Card Found" : "All Cards"}</h2>
              <div className="row">
                {data?.map((item, index) => (
                  <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    {/* <Link to={`/details/${item.slug}`}> */}
                    <ColllectionsCard
                      src={`${url}${item?.card_images[0]?.image}`}
                      name={item.name}
                    />
                    {/* </Link> */}
                  </div>
                ))}
              </div>
            </div>

            {data?.length !== 0 && (
              <div class="col-md-12 text-center mb-5">
                <a href="#" id="loadmore" class="btn-main  fadeInUp lead">
                  Load more
                </a>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex  justify-content-center align-items-center vh-100">
          <MoonLoader size={90} loading={loader} color="#333" />
        </div>
      )}
    </>
  );
};

export default AllcardscC;

import React, { createContext, useEffect, useState } from "react";
export const rootContext = createContext();
export default function RootContext({ children }) {
  const [loader, setLoader] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [updatedCardData, setUpdateCardData] = useState(null);
  const [globalUrl, setGlobalUrl] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [quantitiesone, setQuantitiesone] = useState({});
  const [isCartModal, setIsCartModal] = useState(false);
  const [bannerTitle, setBannerTitle] = useState("");
  const [changRequestData, setChangeRequestData] = useState("");
  const [show,setShow] = useState(false);
  const [isSub, setisSub] = useState(false);
  const [ tabIndexChild,setTabIndexChild] = useState(0);
  const [cartItemsLength, setCartItemsLength] = useState(0);
  const [values, setValues] = useState({
    collection_name: "",
    sub_collection_name: "",
    image: null,
    imageUrl: null,
  });
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLogin(true);
    }
  }, []);

  return (
    <rootContext.Provider
      value={{
        loader,
        setLoader,
        selectedTabIndex,
        setSelectedTabIndex,
        updatedCardData,
        setUpdateCardData,
        globalUrl,
        setGlobalUrl,
        isLogin,
        isCartModal,
        setIsCartModal,
        bannerTitle,
        setBannerTitle,
        changRequestData,
        setQuantities,
        setQuantitiesone,
        quantitiesone,
        quantities,
        setChangeRequestData,
        values,
        setValues,
        show,
        setShow,
        isSub,
        setisSub,
        tabIndexChild,
        setTabIndexChild,
        cartItemsLength,
        setCartItemsLength
      }}
    >
      {children}
    </rootContext.Provider>
  );
}

import React from "react";
import Banners from "../../Components/Banner/Banners";
// import SingleCollection from '../../Components/Collection/SingleCollection'
import SingleActiveCollection from "../../Components/Collection/SingleActiveCollection";

const SingleActives = () => {
  return (
    <>
      <Banners name="Detail" />
      <SingleActiveCollection />
    </>
  );
};

export default SingleActives;

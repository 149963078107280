/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Link, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FaCartPlus, FaMinus, FaPlus } from "react-icons/fa";
import useSubscription from "../../hooks/useSubscription";
import useCart from "../../hooks/useCart";
import { rootContext } from "../../context/RootContext";
import { cardBySubCollectionsSlugByIdActive } from "../../services/cardService";

export default function ForSaleActive({
  data,
  isLogin,
  singleData,
  setLoader,
  setData,
  url,
}) {
  // console.log(setLoader)
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const { isSubscribed } = useSubscription();
  const { addToCart } = useCart();
  const user = JSON.parse(localStorage.getItem("user"));
  const { quantitiesone, setQuantitiesone } = useContext(rootContext);

  useEffect(() => {
    const initialQuantities = {};
    data?.forEach((item) => {
      const userCart = item.carts?.filter((cart) => cart.user_id === user.id);
      initialQuantities[item.id] = userCart?.[0]?.qty || 0;
    });
    setQuantitiesone(initialQuantities);
  }, [data, user.id, setQuantitiesone]);

  const updateQuantity = async (itemId, delta) => {
    setLoader(true);
    
    setQuantitiesone((prevQuantities) => {
      const newQuantity = Math.max((prevQuantities[itemId] || 0) + delta, 0);
  
      // Assuming addToCart returns a Promise
      return addToCart(
        data.find((item) => item.id === itemId),
        newQuantity
      ).then(() => {
        return cardBySubCollectionsSlugByIdActive(id)
          .then((e) => {
            if (e?.card) {
              console.log(e?.card);
              setData(e?.card);
            }
          })
          .catch((e) => {
            console.log("Error fetching card: ", e);
          })
          .finally(() => {
            setLoader(false);
          });
      });
  
      // eslint-disable-next-line no-unreachable
      return {
        ...prevQuantities,
        [itemId]: newQuantity,
      };
    });
  };
  
  useEffect(() => {
    // console.log("Current quantities:", quantitiesone);
  }, [quantitiesone]);

  return (
    <>
      <div className="container">
        <div className="row pb-5">
          <div className={!isLogin ? "col-12 col-md-8" : "col-12"}>
            <Table striped="columns" bordered hover size="sm">
              <thead className="text-center">
                <tr>
                  <th>Detail</th>
                  <th>Name</th>
                  <th>Card Type</th>
                  <th>Quantity</th>
                  {isLogin && <th>For Sale</th>}
                </tr>
              </thead>
              <tbody className="text-center">
                {data?.map((item) => (
                  <tr key={item.id}>
                    <td>{item?.is_detail}</td>
                    <td>{item.name}</td>
                    <td>{item.card_types.name}</td>
                    {isLogin && (
                      <td className="tw-flex tw-items-center tw-justify-center tw-gap-x-10">
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            updateQuantity(item.id, -1);
                            // addToCartone(item, quantities[item.id]);
                          }}
                        >
                          <FaMinus size={10} color="white" />
                        </button>
                        <p className="mt20">{quantitiesone[item.id] || 0}</p>
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            updateQuantity(item.id, 1);
                            // addToCartone(item, quantities[item.id]);
                          }}
                        >
                          <FaPlus size={10} color="white" />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {!isLogin && (
            <div className="col-lg-4">
              <h2>Start Swapping</h2>
              <p>
                <Link to="/signup">Sign up</Link> or{" "}
                <Link to="/login">Login</Link> to your Got Need Swap account to
                start trading with other members today.
              </p>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        dialogClassName="bg-white"
      >
        <Modal.Header className="border-bottom-0 pb-0" closeButton>
          <Modal.Title as="h4" className="w-100 text-center p-0 ">
            {singleData?.name}
          </Modal.Title>
        </Modal.Header>
        <div className="w-100 text-dark p-0 d-flex justify-content-center align-items-center">
          <p>{singleData?.card_types?.name}</p>
        </div>
        <Modal.Body className="text-dark text-center p-0">
          {isSubscribed?.plan?.name !== "PRO" && (
            <button
              className="btn btn-primary"
              onClick={() => {
                addToCart(singleData, quantitiesone[singleData.id]);
              }}
            >
              <FaCartPlus /> <span className="cpl-20">Add to Cart </span> - Mex$
              {singleData?.sale_price * (quantitiesone[singleData.id] || 0)}
            </button>
          )}
          <div className="mt-3">
            {singleData?.card_images?.length > 0 && (
              <img
                src={url + singleData.card_images[0].image}
                className="w-100"
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

import React from "react";
import Banners from "../../Components/Banner/Banners";
// import SingleCollection from '../../Components/Collection/SingleCollection'
// import SingleCollectionActive from "../../Components/Collection/SingleCollectionActive";
import AllcardscC from "../../Components/Collection/AllcardsC";

const Collabrate= () => {
  return (
    <>
      <Banners name="All Card" />
      <AllcardscC />
    </>
  );
};

export default Collabrate;

import { axiosInstance } from "../utils/axiosHelper"

export const getAllSubCollectionsService =async(slug)=>{
   return await axiosInstance.get(`cardCollectionSubTitles?cardCollectionTitleSlug=${slug}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const getAllCards =async(slug,userId)=>{
   return await axiosInstance.get(`user/cardCollabrate?cardCollectionSubTitleSlug=${slug}&user_id=${userId}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}

import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { validatar } from "../../utils/validation";
import Swal from "sweetalert2";
import TextField from "../Form/TextField";
import { ClipLoader } from "react-spinners";
import { getAllCollectionsService } from "../../services/collectionsService";
import SelectField from "../Form/SelectField";
import { getAllSubCollectionsService } from "../../services/subCollectionsService";
import { getAllCardsTypeService } from "../../services/cardTypeService";
import ImageField from "../Form/ImageField";
import {  addUserCollbrate } from "../../services/cardService";
import { rootContext } from "../../context/RootContext";
// import NumberField from "../Form/NumberField";
export default function AddCollabrateCard() {
  const [Loader, setLoader] = useState(false);
  const [collectionsOptions, setCollectionsOptions] = useState([]);
  const [subCollectionsOptions, setSubCollectionsOptions] = useState([]);
  const [cardTypeOptions, setCardTypeOptions] = useState([]);
  const { setSelectedTabIndex } = useContext(rootContext);
  const [resizeCropper, setResizerCropper] = useState(false);
  const allCollectionsData = async () => {
    const result = await getAllCollectionsService();
    if (result.categorycardCollectionTitle) {
      setCollectionsOptions(result.categorycardCollectionTitle);
    } else {
    }
  };
  // sub collection type start
  const allSubCollectionsData = async (name) => {
    const result = await getAllSubCollectionsService(name);
    if (result.cardCollectionSubTitle) {
      setSubCollectionsOptions(result.cardCollectionSubTitle);
    }
  };
  // sub collection type end
  // card type start
  const allCardTypeData = async () => {
    const result = await getAllCardsTypeService();
    if (result.cardType) {
      setCardTypeOptions(result.cardType);
    }
  };
  // card type end
  const formik = useFormik({
    initialValues: {
      // deal:"",
      name: "",
      card_collection_title_id: "",
      card_collection_sub_title_id: "",
      card_type_id: "",
      // sale_price: "",
      // is_for_sale: "",
      image: null,
      // quantity:0,
    },
    validationSchema: yup.object({
      // deal: validatar("Deal Number is required"),
      name: validatar("Title is required"),
      card_collection_title_id: validatar("Collection  is required"),
      card_collection_sub_title_id: validatar("Sub Collection is required"),
      card_type_id: validatar("Card Type required"),
      // sale_price: validatar("Price required"),
      // is_for_sale: validatar("Sale required"),
      // image: validatar("Sale required"),
      // quantity: validatar("Quantity required"),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      // const result = await addUserCard(values);
      const result = await addUserCollbrate(values);

      setLoader(true);
      if (result.card) {
        Swal.fire({
          icon: "success",
          title: "Card",
          text: result.message,
          showConfirmButton: true,
          timer: 15000,
        });
        setLoader(false);
        setSelectedTabIndex(1);
      } else {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Card",
          text: result.message,
          showConfirmButton: true,
          timer: 15000,
        });
      }
      // localStorage.setItem('token',result.accessToken)
      // navigate('/')
    },
  });

  useEffect(() => {
    allCollectionsData();
  }, []);
  useEffect(() => {
    allSubCollectionsData(formik.values.card_collection_title_id.split("&")[1]);
  }, [formik.values.card_collection_title_id]);

  useEffect(() => {
    allCardTypeData();
  }, [formik.values.card_collection_sub_title_id]);
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="text-center">
            <h2 className="text-center ">Add New Collabrate</h2>
          </div>
        </div>
        <div
          className={`${resizeCropper ? "d-none" : "d-block col-md-6 auto"}`}
        >
          <form
            name="contactForm"
            id="contact_form"
            className="form-border"
            onSubmit={formik.handleSubmit}
          >
            <div class="col-md-12">
              <div class="field-set">
                <TextField
                  label="Name"
                  name="name"
                  placeholder="Enter Name"
                  formik={formik}
                />
              </div>
              {/* <div class="col-12">
                <div class="field-set">
                <NumberField
                    label="Deal"
                    name="deal"
                    placeholder="Enter Deal Number"
                    formik={formik}
                    max={40000}
                  />
                </div>
              </div> */}
              {/* <div class="col-md-6">
                <div class="field-set">
                  <SelectField
                    options={[
                        {
                            id:1,
                            name:"For Sale",
                            slug:"none"
                        },
                        {
                            id:0,
                            name:"For Swap",
                            slug:"none"
                        }
                    ]}
                    label="Type"
                    name="is_for_sale"
                    placeholder="Is for Sales"
                    formik={formik}
                   
                  />
                </div>
              </div> */}
              {/* <div class="col-md-6">
                <div class="field-set">
                <NumberField
                    label="Quantity"
                    name="quantity"
                    placeholder="Enter Quantity"
                    formik={formik}
                    max={400}
                  />
                </div>
              </div> */}

              {/* <div class="col-md-12">
                <div class="field-set">
              
                  <NumberField
                    label="Price"
                    name="sale_price"
                    placeholder="Enter Price $"
                    formik={formik}
                    max={100000}
                  />
                </div>
              </div> */}
              <div class="col-md-12">
                <div class="field-set">
                  <SelectField
                    options={collectionsOptions}
                    label="Collection "
                    name="card_collection_title_id"
                    placeholder="Select Card Collection"
                    formik={formik}
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="field-set">
                  <SelectField
                    options={subCollectionsOptions}
                    label="Sub Collection "
                    name="card_collection_sub_title_id"
                    placeholder="Select Sub Collection"
                    formik={formik}
                    disabled={
                      !formik.values.card_collection_title_id ? true : false
                    }
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="field-set">
                  <SelectField
                    options={cardTypeOptions}
                    label="Card Type"
                    name="card_type_id"
                    placeholder="Select Card Type"
                    formik={formik}
                    disabled={
                      !formik.values.card_collection_sub_title_id ? true : false
                    }
                  />
                </div>
              </div>

              {/* name:card 6u9nyu card_collection_title_id:8
              card_collection_sub_title_id:8 card_type_id:3 sale_price:1000
              is_for_sale:1 */}
              {/* <div className="col-12">
              <div class="mb-3 form-check agreemnt-checkbox-div">
                        <input type="checkbox" class="form-check-input agreement-checkbox" id="terms"/>
                        <label class="form-check-label" for="terms">
                        I agree with my counterparty to not upload the images.

                        </label>
                    </div>
              </div> */}
              <div class="col-md-12 py-4">
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    type="submit"
                    id="send_message"
                    class="btn btn-main color-2 d-block m-auto "
                    style={{ width: "300px" }}
                    disabled={Loader}
                  >
                    {" "}
                    Add Collabrate <ClipLoader
                      loading={Loader}
                      size={14}
                    />{" "}
                  </button>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </form>
        </div>
        <div className={`${resizeCropper ? "col-12" : "col-md-4"}`}>
          <ImageField
            label=""
            name="image"
            placeholder="Enter Title"
            formik={formik}
            setResizerCropper={setResizerCropper}
          />
        </div>
      </div>
    </div>
  );
}

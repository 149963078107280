import { axiosInstance } from "../utils/axiosHelper"


export const registerUserService=async(data)=>{
   return await axiosInstance.post('userRegister',data)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const loginUserService=async(data)=>{
   return await axiosInstance.post('userLogin',data)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const ForgotPasswordService=async(data)=>{
   return await axiosInstance.post('forgot',data)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}

export const ResetPasswordService=async(data)=>{
   return await axiosInstance.post('reset',data)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const VerifyuSignupService=async(token)=>{
   return await axiosInstance.get(`signup/verifyemail?token=${token}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}

export const profileDataServce=async()=>{
   return await axiosInstance.get('user/profile')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}


export const UpdateUserProfileService =async(data,id)=>{
   const formdata = new FormData();
   formdata.append('email',data.email)
   if(data.image !==null &&  typeof data.image !=="string"){

      formdata.append('image',data.image)
   }
   formdata.append('forename',data.forename)
   formdata.append('surname',data.surname)
   formdata.append('username',data.username)
   formdata.append('state',data.state)
   formdata.append('zipcode',data.zipcode)
   formdata.append('_method',"PUT");
    return await axiosInstance.post(`user/profile/${data.id}`,formdata)
    .then((res)=>{
     return res.data
    })
    .catch((err)=>{
     return err.response.data
    })
 }
 export const addSubecriptionsNew =async(upgrade)=>{
   const formdata = new FormData();
   // formdata.append('plan_id',id);
   // if(upgrade !==0){
      formdata.append('protection_price',upgrade);
   // }
   return await axiosInstance.post('user/protections',formdata)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}



export const UpdateUserPasswordService =async(data)=>{
 
    return await axiosInstance.post(`user/changePassword`,data)
    .then((res)=>{
     return res.data
    })
    .catch((err)=>{
     return err.response.data
    })
 }
export const UpdateDeliveryMethodService =async(data)=>{
   const formdata = new FormData();
       formdata.append('key[0]',"Presential Users agree a one to one meeting in a public place.")
      if(data.includes('Presential Users agree a one to one meeting in a public place.')){

         formdata.append('value[0]',1)
      }
      else{
         formdata.append('value[0]',0)

      }
       formdata.append('key[1]',"Courier Users send the stickers by postal service, or the courier of their preference.")
      if(data.includes('Courier Users send the stickers by postal service, or the courier of their preference.')){

         formdata.append('value[1]',1)
      }
      else{
         formdata.append('value[1]',0)

      }
 
       formdata.append('key[2]',"Estampers Users send the stickers through Estampers validation system.*Your actual plan need to be upgraded for this option.")
      if(data.includes('Estampers Users send the stickers through Estampers validation system.*Your actual plan need to be upgraded for this option.')){

         formdata.append('value[2]',1)
      }
      else{
         formdata.append('value[2]',0)

      }
 
       formdata.append('key[3]',"Events Users attend events promoted by Estampers for its community .")
      if(data.includes('Events Users attend events promoted by Estampers for its community .')){

         formdata.append('value[3]',1)
      }
      else{
         formdata.append('value[3]',0)

      }
 
    return await axiosInstance.post(`user/deliveryMethod`,formdata)
    .then((res)=>{
     return res.data
    })
    .catch((err)=>{
     return err.response.data
    })
 }


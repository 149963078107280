import React, { useEffect, useState } from "react";
import { getAllCollectionsPublicService } from "../../services/collectionsService";
import ColllectionsCard from "../../Components/Home/ColllectionsCard";
// import { Link } from "react-router-dom";

export default function AvailableCollections() {
  const [data, setData] = useState([]);
  const [url, setUrl] = useState("");
  const [, setLoader] = useState(false);

  const allCollectionsData = async () => {
    setLoader(true);
    const result = await getAllCollectionsPublicService();
    if (result.categorycardCollectionTitle) {
      setData(result.categorycardCollectionTitle);
      setLoader(false);
      setUrl(result.imagePath);
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    allCollectionsData();
  }, []);
  return (
    <>
      <div className="row">
        <h2> Available Collections</h2>
        {data.length > 0 &&
          data.slice(0, 8)?.map((item, index) => (
            <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
              {/* <Link to={`/collectionC/${item.slug}`}> */}
                <ColllectionsCard
                  src={`${url}${item.image}`}
                  name={item.name}
                />
              {/* </Link> */}
            </div>
          ))}
      </div>
    </>
  );
}

import React from "react";
import Banners from "../../Components/Banner/Banners";
// import SingleCollection from '../../Components/Collection/SingleCollection'
import SingleCollectionActive from "../../Components/Collection/SingleCollectionActive";

const SingleActive = () => {
  return (
    <>
      <Banners name="single Collection" />
      <SingleCollectionActive />
    </>
  );
};

export default SingleActive;
